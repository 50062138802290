import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { Respuesta } from "./components/Respuesta";
import SingleCard from "./components/SingleCard";

const cardImages = [
  {
    src: "/img/FICHAS/001/001_BIEN_A.png",
    front: "/img/FICHAS/001/001_ICON.png",
    res: "/img/FICHAS/001/001_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/002/002_BIEN_A.png",
    front: "/img/FICHAS/002/002_ICON.png",
    res: "/img/FICHAS/002/002_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/003/003_BIEN_A.png",
    front: "/img/FICHAS/003/003_ICON.png",
    res: "/img/FICHAS/003/003_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/004/004_BIEN_A.png",
    front: "/img/FICHAS/004/004_ICON.png",
    res: "/img/FICHAS/004/004_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/005/005_BIEN_A.png",
    front: "/img/FICHAS/005/005_ICON.png",
    res: "/img/FICHAS/005/005_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/006/006_BIEN_A.png",
    front: "/img/FICHAS/006/006_ICON.png",
    res: "/img/FICHAS/006/006_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/001/001_BIEN_B.png",
    front: "/img/FICHAS/001/001_ICON.png",
    res: "/img/FICHAS/001/001_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/002/002_BIEN_B.png",
    front: "/img/FICHAS/002/002_ICON.png",
    res: "/img/FICHAS/002/002_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/003/003_BIEN_B.png",
    front: "/img/FICHAS/003/003_ICON.png",
    res: "/img/FICHAS/003/003_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/004/004_BIEN_B.png",
    front: "/img/FICHAS/004/004_ICON.png",
    res: "/img/FICHAS/004/004_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/005/005_BIEN_B.png",
    front: "/img/FICHAS/005/005_ICON.png",
    res: "/img/FICHAS/005/005_RESPUESTA.png",
    matched: false,
  },
  {
    src: "/img/FICHAS/006/006_BIEN_B.png",
    front: "/img/FICHAS/006/006_ICON.png",
    res: "/img/FICHAS/006/006_RESPUESTA.png",
    matched: false,
  },
];

function App() {
  const [cards, setCards] = useState([]);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [respuesta, setRespuesta] = useState({ res: "", flipped: false });
  const [respuestaDos, setRespuestaDos] = useState("");
  const [contador, setContador] = useState(0);

  // shuffle cards for new game
  const shuffleCards = () => {
    const shuffledCards = [...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setRespuesta({ res: "", flipped: false });
  };

  // handle a choice
  const handleChoice = (card) => {
    console.log(card);
    setRespuesta({ res: respuestaDos, flipped: false });
    // setTimeout(handleRespuesta,20000)
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  //quita la respuesta
  const handleRespuesta = () => {
    console.log("ACA hago la acción");
    setRespuesta({ res: "", flipped: false });
    setRespuestaDos("");
  };

  // ir al final

  let navigate = useNavigate();

  const ganador = () => {
    navigate("/final");
  };

  // compare 2 selected cards
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (
        choiceOne.front === choiceTwo.front &&
        choiceOne.src !== choiceTwo.src
      ) {
        setRespuesta({ res: choiceOne.res, flipped: true });
        setContador(contador + 1);
        setRespuestaDos(choiceOne.res);
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.front === choiceOne.front) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => resetTurn(), 1000);
      }
    }
    if (contador === 6) {
      setTimeout(ganador, 10000);
    }
  }, [choiceOne, choiceTwo]);

  // reset choices & increase turn
  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setDisabled(false);
  };

  // start new game automagically
  useEffect(() => {
    shuffleCards();
  }, []);

  return (
    <>
    <div className="top-banner2">
    <button type="button" className="button2"></button>
    </div>
    
    <div className="game">
      <div className="App">
        <div className="Vyuu">
          <div className="respuesta">
            <Respuesta card={respuesta.res} flipped={respuesta.flipped} />
          </div>

          <div className="card-grid">
            {cards.map((card) => (
              <SingleCard
                key={card.id}
                card={card}
                handleChoice={handleChoice}
                flipped={
                  card === choiceOne || card === choiceTwo || card.matched
                }
                disabled={disabled}
              />
            ))}
          </div>
        </div>
        {/* 
      <p>Turnos {turns}</p> */}
      </div>
    </div>
    </>
  );
}

export default App;
