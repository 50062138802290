import React from 'react'

export function Respuesta(props) {

  return (
    <div className={props.flipped ? "flipped" : "res-img"}>
      <img className="res-img-1" src={props.card} alt="" />
    </div>
  )


}
