import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import fileDownload from 'js-file-download';
import axios from 'axios';


export  function Inicio() {

    let navigate = useNavigate();
    const [popup, setPopup] = useState(false);
  



  const jugar = () => {

    navigate("app")

  } 

  const abrirPop = () => {
    console.log("Abrirrrrrr")
    setPopup(true);
  }

  const cerrarPop = () => {
    console.log("CERRRAAAAAR")
    setPopup(false);
  }


  const handleDownload = async () => {
    axios.get('./docs/doc2.pdf', {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, 'reporte alto costo cancer - Roche.pdf')

      axios.get('./docs/doc1.pdf', {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'Interoperabilidad en salud.pdf')
      })
    })
    // axios.get('./docs/doc1.pdf', {
    //   responseType: 'blob',
    // })
    // .then((res) => {
    //   fileDownload(res.data, 'Interoperabilidad en salud.pdf')
    // })
  }

  return (

    <>
    <Popup open={popup} onClose={cerrarPop} >        
    <div className="modal">  
    <a className="close" onClick={cerrarPop}>Cerrar</a>        
      </div> 
      <iframe src="https://player.castr.com/vod/Qyi4HtahpO3DnXex" frameborder="0" width="590" height="430" allow="autoplay"  scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>     
    </Popup>
    <div className="top-banner">
    <button className='top-btn-1' onClick={handleDownload}></button>
    <button className='top-btn-2' onClick={abrirPop}></button>
    </div>
    <div className='game'>
    
      <div className='btn-ubicar-play'>
      <button className='button' onClick={jugar}>
      </button>
      </div>
    </div>
    </>
  )
}
