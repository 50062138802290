import React from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'

export function Final() {


  const handleDownload = async () => {
    axios.get('./docs/doc2.pdf', {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, 'reporte alto costo cancer - Roche.pdf')

      axios.get('./docs/doc1.pdf', {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'Interoperabilidad en salud.pdf')
      })
    })
    // axios.get('./docs/doc1.pdf', {
    //   responseType: 'blob',
    // })
    // .then((res) => {
    //   fileDownload(res.data, 'Interoperabilidad en salud.pdf')
    // })
  }

  return (
    <div className='final'>
        <div className='Vyuu'>

            <button className="final-btn" onClick={handleDownload}>

               <img className="final-img" src="/img/BOTON_DESCARGAR.png" alt="" />
            </button>
        </div>


    </div>
  )
}
