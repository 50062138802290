import React from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './App';
import {Inicio} from "./components/Inicio"
import { Final } from './Final';

export function Home() {


  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element= {<Inicio/>}/>
    <Route path="app" element= {<App/>}/>
    <Route path="final" element= {<Final/>}/>
    </Routes>
    </BrowserRouter>
  )
}
